import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "Can I take a Solo 401k loan? How does it work?",
        value: "Can I take a Solo 401k loan? How does it work?"
    },
    {
        text: "Can I take a Solo 401k loan if I already have a loan from my regular day job 401k?",
        value: "Can I take a Solo 401k loan if I already have a loan from my regular day job 401k?"
    },
    {
        text: "Can I pre-pay my Solo 401k loan?",
        value: "Can I pre-pay my Solo 401k loan?"
    },
    {
        text: "Do I make solo 401k loan payments to myself?",
        value: "Do I make solo 401k loan payments to myself?"
    },
    {
        text: "How is interest rate determined for solo 401k loan?",
        value: "How is interest rate determined for solo 401k loan?"
    },
    {
        text: "What is the solo 401k loan grace period?",
        value: "What is the solo 401k loan grace period?"
    },
    {
        text: "Can I make a Solo 401k loan to my own self-employed business?",
        value: "Can I make a Solo 401k loan to my own self-employed business?"
    },
    {
        text: "How do I take a solo 401k loan from a brokerage account (Fidelity, Schwab, TD Ameritrade)?",
        value: "How do I take a solo 401k loan from a brokerage account (Fidelity, Schwab, TD Ameritrade)?"
    },
    {
        text: "How can I use my solo 401k funds to repair my home?",
        value: "How can I use my solo 401k funds to repair my home?"
    },
    {
        text: "Are Solo 401k loan payments included in Solo 401k contributions?",
        value: "Are Solo 401k loan payments included in Solo 401k contributions?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListSolo401kLoans = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
