import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, Navigate, BrowserRouter  } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";
import React, { useRef, useState, useEffect } from "react";
import Layout from "./pages/layout/Layout";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import BusinessFinancing from "./pages/businessFinancing/businessFinancing"
import IraLLC from "./pages/irallc/irallc";
import NoPage from "./pages/noPage/NoPage";
import Callback from "./pages/callback/Callback";
initializeIcons();


export default function App() {

  // const [chatProps, setChatProps] = useState<string>("");

  // const receiveChatProps = (props: string) => {
  //   setChatProps(props);
  //   console.log("Chat Props in App:", props);
  // };

    return (
      
      <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<NoPage />} />
          <Route path="solo401k" element={<Chat/>} />
          <Route path="robs401k" element={<BusinessFinancing />} />
          <Route path="irallc" element={<IraLLC />} />
          <Route path="callback" element={<Callback />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/*" element={<Navigate to="/solo401k" replace />} />
      </Routes>
    </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
