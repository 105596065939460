import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import { useNavigate } from 'react-router-dom'
import { SparkleFilled } from "@fluentui/react-icons";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import onedigital from "../../assets/my-solo-logo.png";
import onedigital_small from "../../assets/mysolo_small.png";
import styles from "./Chat.module.css";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn, loginApi, tokenApi, TokenRequest } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example/ExampleOpenAccount";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import ReactSelectBox from "../../components/ReactSelectBox/ReactSelectBox";

const Callback = () => {
    const navigate = useNavigate();
    useEffect(() => {
        
        
          const fetchAccessToken = async () => {
            try {
              const urlSearchParams = new URLSearchParams(location.search);
              const code = urlSearchParams.get('code');
      
              if (code) {
                const request: TokenRequest = {
                    code: code,
                }
                

                const result = await tokenApi(request);
                console.log(result.message); // Handle the response as needed
                if (result.message == "Authorization successful"){
                    navigate("/solo401k")
                    
                    
                }
              } else {
                console.error('Authorization code not found in URL');
              }
            } catch (error) {
              console.error('Error fetching access token:', error);
            } finally {
              // Redirect back to the home page or any other desired route
              
            }
          };
      
          fetchAccessToken();

    });
   


    return (
        
        
        <div>
           
            
        </div>
    );
};

export default Callback;
