import React, { useState } from "react";

import { Stack, TextField } from "@fluentui/react";

import { Send28Filled } from "@fluentui/react-icons";

 

import styles from "./QuestionInput.module.css";

 

interface Props {

    onSend: (question: string) => void;

    disabled: boolean;

    placeholder?: string;

    clearOnSend?: boolean;

}

 

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {

    const [question, setQuestion] = useState<string>("");

 

    const sendQuestion = () => {

        if (disabled || !question.trim() || isOver200) {

            return;

        }

 

        onSend(question);

 

        if (clearOnSend) {

            setQuestion("");

        }

    };

 

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {

        if (ev.key === "Enter" && !ev.shiftKey) {

            ev.preventDefault();

            sendQuestion();

        }

    };

    const onQuestionPaste = (ev: React.ClipboardEvent) => {

        const clipboardData = ev.clipboardData;

        const pastedText = clipboardData.getData('text/plain');

     

        // Define a regular expression pattern to match special characters

        const pattern = /[/()\-[\]{}:;\\|<>"]/g;

     

        // Replace special characters with an empty string

        const cleanedText = pastedText.replace(pattern, '');

        

        // Prevent the default paste behavior

        ev.preventDefault();
        setQuestion(cleanedText)
 


    };

    const onQuestionKeyDown = (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        // Prevent typing of certain characters

        if (ev.key === "/" || ev.key === "("  || ev.key === ")" || ev.key === "-" || ev.key === "["|| ev.key === "]" || ev.key === "{" || ev.key === "}" || ev.key === ":" || ev.key === ";" || ev.key === "\\" || ev.key === '"' || ev.key === '|' || ev.key === '>' || ev.key === '<') {

            ev.preventDefault();

        }

    };

 

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

        if (!newValue) {

            setQuestion("");

        } else if (newValue.length <= 1000) {

            setQuestion(newValue);

        }

    };

 

    const characterCount = question.length;

    const characterLimit = 200;

    const isOver100 = characterCount > 100;

    const isOver200 = characterCount > 200;

    const sendButtonDisabled = disabled || !question.trim() || isOver200;

 

    return (

        <Stack verticalAlign="center">

         

             {isOver200 ? (

                <div className={styles.characterLimitMessage200}>Character limit exceeded</div>

            ) : (

                isOver100 && <div className={styles.characterLimitMessage}>

                <span className={styles.dangerIcon}></span>

                Keep your questions concise for optimal results

            </div>

            )}

            <Stack horizontal className={styles.questionInputContainer}>

            <TextField

                className={`${styles.questionInputTextArea} ${isOver100 ? styles.highlighted : ""}`}

                placeholder={placeholder}

                multiline

                resizable={false}

                borderless

                value={question}

                onChange={onQuestionChange}

                onKeyDown={onQuestionKeyDown}

                onKeyPress={onEnterPress}

                onPaste={onQuestionPaste}

            />

            <div className={isOver200 ? styles.characterCountExceeded: styles.characterCount}>{characterCount}/{characterLimit}</div>

            <div className={styles.questionInputButtonsContainer}>

                <div

                    className={`${styles.questionInputSendButton} ${sendButtonDisabled ? styles.questionInputSendButtonDisabled : ""}`}

                    aria-label="Ask question button"

                    onClick={sendQuestion}

                >

                    <Send28Filled primaryFill="#541e50" />

                </div>

               

            </div>

            </Stack>

           

        </Stack>

    );

};