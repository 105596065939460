import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {'text': 'How much salary can I receive?', 'value': 'How much salary can I receive?'},
    {'text': 'Can the business reimburse me for expenses paid with personal funds?', 'value': 'Can the business reimburse me for expenses paid with personal funds?'},
    {'text': 'Do I need to offer the 401k to my employees?', 'value': 'Do I need to offer the 401k to my employees?'},
    {'text': 'Do I need to obtain a valuation every year?', 'value': 'Do I need to obtain a valuation every year?'},
    {'text': 'Where can I invest the funds not invested in the business?', 'value': 'Where can I invest the funds not invested in the business?'},
    {'text': 'Can I invest more retirement money in my business?', 'value': 'Can I invest more retirement money in my business?'},
    {'text': 'Do I have to make contributions to the 401k?', 'value': 'Do I have to make contributions to the 401k?'},
    {'text': 'How can I take profits out of my business?', 'value': 'How can I take profits out of my business?'},
    {'text': 'Do I have to file a tax return for my business?', 'value': 'Do I have to file a tax return for my business?'},
    {'text': 'Can my ROBS C-corp transact with another business I own?', 'value': 'Can my ROBS C-corp transact with another business I own?'},
    {'text': 'Can I operate multiple lines of business?', 'value': 'Can I operate multiple lines of business?'}
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListOngoingRequirements = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
