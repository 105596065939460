import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}
export const Example = ({ text, value, onClick }: Props) => {
    return (
       
        <div className={styles.example} onClick={() => onClick(value)}>
        <div className={styles.stack}>
            <p className={styles.exampleText}>{text}</p>
            <div className="button-container">
            <button className={styles.button}><Send28Filled primaryFill="#541e50" /></button>
            </div>
        </div>
        </div>
    );
};
