export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    session_id?:number,
    retrievecount?:number
};
export type EmailResponse = {
    message: string;
    error: string;
  
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    category: string;
    overrides?: AskRequestOverrides;
};

export type LoginResponse = {
    email: string;
    password: Approaches;
    category: string;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    titles: string[];
    indexes: string[];
    matching_link: string[];
    matching_link_names: string[];
    error?: string;
};

export type TokenRequest = {
    code: string;
  
};

export type TokenResponse = {
    message: string;
    error: string;
  
};

export type LoginResponseUrl = {
    authorization_url: string;
    error?: string;
   
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    category: string;
    overrides?: AskRequestOverrides;
};

export type ChatEmailRequest = {
    time ?: string

}

export type EmailOverrides = {
    time?: String
}

export type ChatEmailResponse = {
    message?: string,
    error?: string
}