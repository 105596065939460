import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "Is a Form 1099-R Required for Voluntary After-Tax Solo 401k (Mega Backdoor Roth) Contributions?",
        value: "Is a Form 1099-R Required for Voluntary After-Tax Solo 401k (Mega Backdoor Roth) Contributions?"
    },
    {
        text: "When is the 1099 R issued for Mega Backdoor Roth Solo 401k?",
        value: "When is the 1099 R issued for Mega Backdoor Roth Solo 401k?"
    },
    {
        text: "Do I need to file a Final Form 5500 EZ when closing a Solo 401k with less than $250k?",
        value: "Do I need to file a Final Form 5500 EZ when closing a Solo 401k with less than $250k?"
    },
    {
        text: "Which EIN do I use on the Form 5500-EZ?",
        value: "Which EIN do I use on the Form 5500-EZ?"
    },
    {
        text: "When is the deadline for filing Form 5500 EZ for my Solo 401k?",
        value: "When is the deadline for filing Form 5500 EZ for my Solo 401k?"
    },
    {
        text: "Does changing my solo 401k to another provider require a Form 5500-EZ?",
        value: "Does changing my solo 401k to another provider require a Form 5500-EZ?"
    },
    {
        text: "Do I combine all Self-directed 401k accounts to see if the $250K 5500 EZ filing requirement applies?",
        value: "Do I combine all Self-directed 401k accounts to see if the $250K 5500 EZ filing requirement applies?"
    },
    {
        text: "Is a separate Form 5500 EZ filed for each Solo 401k participant?",
        value: "Is a separate Form 5500 EZ filed for each Solo 401k participant?"
    },
    {
        text: "Are conversions, distributions, or rollovers out of the solo 401k to an IRA reported on the Form 5500-ez?",
        value: "Are conversions, distributions, or rollovers out of the solo 401k to an IRA reported on the Form 5500-ez?"
    },
    {
        text: "Who issues the Form 1099-R for Distributions from the Solo 401k?",
        value: "Who issues the Form 1099-R for Distributions from the Solo 401k?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListPlanReporting = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
