import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {'text': 'How can you charge so much less?', 'value': 'How can you charge so much less?'},
    {'text': 'I see other providers charging more - do you offer less services?', 'value': 'I see other providers charging more - do you offer less services?'},
    {'text': 'Do you prepare the Form 5500?', 'value': 'Do you prepare the Form 5500?'},
    {'text': 'Do you offer audit protection?', 'value': 'Do you offer audit protection?'},
    {'text': 'Why is the first year of support included in your initial fee?', 'value': 'Why is the first year of support included in your initial fee?'},
    {'text': 'After my business is funded will you answer additional questions that come up?', 'value': 'After my business is funded will you answer additional questions that come up?'},
    {'text': 'Can you help me obtain an SBA loan?', 'value': 'Can you help me obtain an SBA loan?'}
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListUSvCompetition = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
