import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
    titles_fin: string[];
    indexes_fin: string[];
   
};

export function parseAnswerToHtml(answer: string, titles: string[], indexes: string[], matching_link: string[], matching_link_names: string[], onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];
    const titles_fin: string[] = [];
    const indexes_fin: string[] = [];
    const checkedItems: string[] = [];
    const checkedItemsIndex: string[] = [];

    const followupQuestionsfin: string[] = [];
    let followupQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
       
        followupQuestionsfin.push(content);
        return "";
    });
   
    if (followupQuestionsfin.length === 1){
        followupQuestions = followupQuestionsfin[0].split(/\?(?=\s|$)/);
       
        for (let i = 0; i< followupQuestions.length; i++){
            if (followupQuestions[i].length > 5){
                followupQuestions[i] = followupQuestions[i] + '?'
            }
        }
        followupQuestions.pop()

    }
    else {
        followupQuestions = followupQuestionsfin
    }
    

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    // console.log(parts)
    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePath(part);

            return renderToStaticMarkup(
                <a className="supContainer" onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });
    // const splitList = titles.map(item => {
    //     const splitItem = item.split(':');
    //     if (splitItem.length === 3 && citations.includes('https:'+ splitItem[2])) {
    //         console.log("in")
    //         titles_fin.push(splitItem[0]);
    //     }
    //     return titles_fin;
    //   });
    
    // console.log(indexes)
    const splitList_indexes = indexes.map(item => {
        const splitItem = item.split(':');
        if (splitItem.length === 3 && citations.includes(splitItem[0]) && !checkedItemsIndex.includes(splitItem[0])) {
       
          indexes_fin.push('https:'+splitItem[2]);
          checkedItemsIndex.push(splitItem[0]); // Add item to checkedItems
        }
        return indexes_fin;
      });
    const splitList = titles.map(item => {
        const splitItem = item.split(':');
        if (splitItem.length === 3 && indexes_fin.includes('https:' + splitItem[2]) && !checkedItems.includes(splitItem[0])) {
       
          titles_fin.push(splitItem[0]);
          checkedItems.push(splitItem[0]); // Add item to checkedItems
        }
        return titles_fin;
      });
    
    // console.log(citations)
    // console.log(indexes_fin)
    const splitList2 = [...new Set(splitList)];
    // console.log(splitList2)
    // console.log("citations")
    // console.log(citations)
    for (let i=0; i< matching_link.length; i++){
        indexes_fin.push(matching_link[i])
        
    }

    for (let i=0; i< matching_link_names.length; i++){
        titles_fin.push(matching_link_names[i])
        
    }
    function removeDuplicates(list1: string[], list2: string[]): void {
        const seen: Set<string> = new Set();
        const indicesToRemove: number[] = [];
    
        for (let i = 0; i < list1.length; i++) {
            const item = list1[i];
            if (seen.has(item)) {
                indicesToRemove.push(i);
            } else {
                seen.add(item);
            }
        }
    
        for (let i = indicesToRemove.length - 1; i >= 0; i--) {
            const idx = indicesToRemove[i];
            list1.splice(idx, 1);
            list2.splice(idx, 1);
        }
    }
    removeDuplicates(indexes_fin, titles_fin);


    return {
        answerHtml: fragments.join(""),
        citations,
        followupQuestions,
        titles_fin,
        indexes_fin
    };
}
