import { Example } from "../ExampleOpenAccount/Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    
    {
        text: "How much does it cost to setup Solo401K?",
        value: "How much does it cost to setup Solo401K?"
    },
    {
        text: "How long does it take to open a Solo 401k?",
        value: "How long does it take to open a Solo 401k?"
    },
    {
        text: "Where are my accounts for my Solo 401k?",
        value: "Where are my accounts for my Solo 401k?"
    },
    {
        text: "Do I need an EIN for my self-employed business to open a Solo 401k?",
        value: "Do I need an EIN for my self-employed business to open a Solo 401k?"
    },
    {
        text: "Can my spouse participate in the Solo 401k?",
        value: "Can my spouse participate in the Solo 401k?"
    },
    {
        text: "Can I open a Solo 401k if I also have a 401k through my employer?",
        value: "Can I open a Solo 401k if I also have a 401k through my employer?"
    },
    {
        text: "Can  rollover funds to my solo 401k?",
        value: "Can  rollover funds to my solo 401k?"
    },
    {
        text: "Do I need an entity for my self-employed business to open a Solo 401k?",
        value: "Do I need an entity for my self-employed business to open a Solo 401k?"
    },
    {
        text: "Can I set up a solo 401k if my business has full-time w-2 employees?",
        value: "Can I set up a solo 401k if my business has full-time w-2 employees?"
    },
    {
        text: "What name should I use for the Solo 401k?",
        value: "What name should I use for the Solo 401k?"
    },
    {
        text: "Do I need an EIN for my self-employed business to open a Solo 401k?",
        value: "Do I need an EIN for my self-employed business to open a Solo 401k?"
    },
    {
        text: "Do I need an EIN for my self-employed business to open a Solo 401k?",
        value: "Do I need an EIN for my self-employed business to open a Solo 401k?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}
function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
