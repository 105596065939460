import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "How much can I contribute to the Solo 401k?",
        value: "How much can I contribute to the Solo 401k?"
    },
    {
        text: "What is the deadline to make contributions to the Solo 401k?",
        value: "What is the deadline to make contributions to the Solo 401k?"
    },
    {
        text: "Do I have to contribute to my Solo 401k?",
        value: "Do I have to contribute to my Solo 401k?"
    },
    {
        text: "How do I make contributions?",
        value: "How do I make contributions?"
    },
    {
        text: "How do I track contributions to the solo 401k plan?",
        value: "How do I track contributions to the solo 401k plan?"
    },
    {
        text: "Can I contribute W-2 wages from my day job to my self-directed solo 401k?",
        value: "Can I contribute W-2 wages from my day job to my self-directed solo 401k?"
    },
    {
        text: "How often can I make contributions to my self-employed Solo 401k?",
        value: "How often can I make contributions to my self-employed Solo 401k?"
    },
    {
        text: "Can I contribute to an IRA and a Solo 401k?",
        value: "Can I contribute to an IRA and a Solo 401k?"
    },
    {
        text: "Do I need separate brokerage accounts for my Pre-tax and Roth Solo 401k funds?",
        value: "Do I need separate brokerage accounts for my Pre-tax and Roth Solo 401k funds?"
    },
    {
        text: "Can I make 401k contributions from multiple streams of self-employment income?",
        value: "Can I make 401k contributions from multiple streams of self-employment income?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListContributions = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
