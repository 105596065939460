import { Example } from "../ExampleOpenAccount/Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {'text': 'How much does it cost to open an IRA LLC?', 'value': 'How much does it cost to open an IRA LLC?'},
    {'text': 'Who serves the custodian of the IRA funded LLC?', 'value': 'Who serves the custodian of the IRA funded LLC?'},
    {'text': 'How do I get started in opening the IRA LLC?', 'value': 'How do I get started in opening the IRA LLC?'},
    {'text': 'How long does the IRA LLC setup process take?', 'value': 'How long does the IRA LLC setup process take?'},
    {'text': 'Can the IRA LLC be formed in any state?', 'value': 'Can the IRA LLC be formed in any state?'},
    {'text': 'Can a brokerage firm such as Fidelity serve as the custodian of the IRA LLC?', 'value': 'Can a brokerage firm such as Fidelity serve as the custodian of the IRA LLC?'},
    {'text': 'Which bank can I use for the LLC funded IRA?', 'value': 'Which bank can I use for the LLC funded IRA?'},
    {'text': 'What reporting applies to the IRA LLC?', 'value': 'What reporting applies to the IRA LLC?'},
    {'text': 'How do I pay the IRA LLC setup fees?', 'value': 'How do I pay the IRA LLC setup fees?'}
];

interface Props {
    onExampleClicked: (value: string) => void;
}
function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
