import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "What can my solo 401k invest in?",
        value: "What can my solo 401k invest in?"
    },
    {
        text: "How do I title my Solo 401k Investment in Private Equity/Private Placement?",
        value: "How do I title my Solo 401k Investment in Private Equity/Private Placement?"
    },
    {
        text: "How do I title investment made with both Pre tax & Roth Solo 401k funds?",
        value: "How do I title investment made with both Pre tax & Roth Solo 401k funds?"
    },
    {
        text: "Can my Solo 401k make crowdfunded REIT investments (Fundrise, Realty Mogul)?",
        value: "Can my Solo 401k make crowdfunded REIT investments (Fundrise, Realty Mogul)?"
    },
    {
        text: "Can I invest my Solo 401k funds in private company shares?",
        value: "Can I invest my Solo 401k funds in private company shares?"
    },
    {
        text: "Which is Best for Self-Directed 401k Private Placement Investment?",
        value: "Which is Best for Self-Directed 401k Private Placement Investment?"
    },
    {
        text: "Can I lend my Solo 401k funds to my sister?",
        value: "Can I lend my Solo 401k funds to my sister?"
    },
    {
        text: "Can I invest my Solo 401k in a work of art?",
        value: "Can I invest my Solo 401k in a work of art?"
    },
    {
        text: "How can I trade options in my self-employed solo 401k?",
        value: "How can I trade options in my self-employed solo 401k?"
    },
    {
        text: "Can I invest the solo 401k in a real estate syndicate?",
        value: "Can I invest the solo 401k in a real estate syndicate?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListInvestments = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
