import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {'text': 'How do I complete/ fill out a Form W-9?', 'value': 'How do I complete/ fill out a Form W-9?'},
    {'text': 'If I form an IRA LLC with 50% IRA funds and 50% my own non IRA funds, can I write off depreciation expense on my non IRA fund income ?', 'value': 'If I form an IRA LLC with 50% IRA funds and 50% my own non IRA funds, can I write off depreciation expense on my non IRA fund income ?'},
    {'text': 'If my wife and I invest both of our respective IRAs in the same LLC, will that trigger any LLC tax return filing requirement?', 'value': 'If my wife and I invest both of our respective IRAs in the same LLC, will that trigger any LLC tax return filing requirement?'},
    {'text': 'If I purchase real estate through the IRA LLC and collect rent, who will the check be made out to?', 'value': 'If I purchase real estate through the IRA LLC and collect rent, who will the check be made out to?'},
    {'text': 'Do I have to file a 1099-MISC for vendors who provide services to the IRA LLC?', 'value': 'Do I have to file a 1099-MISC for vendors who provide services to the IRA LLC?'},
    {'text': 'What are the UBIT tax implications for real estate investing via the IRA LLC?', 'value': 'What are the UBIT tax implications for real estate investing via the IRA LLC?'},
    {'text': 'What is a non-recourse loan?', 'value': 'What is a non-recourse loan?'},
    {'text': 'Can I use my SD Roth IRA account and obtain an SBA loan?', 'value': 'Can I use my SD Roth IRA account and obtain an SBA loan?'},
    {'text': 'Can I use the IRA LLC to finance my own business?', 'value': 'Can I use the IRA LLC to finance my own business?'}
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListInvestments = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
