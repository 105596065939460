import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {'text': 'Can I use my 401k to start a real estate operating company?', 'value': 'Can I use my 401k to start a real estate operating company?'},
    {'text': 'Can I use real estate I already own?', 'value': 'Can I use real estate I already own?'},
    {'text': 'What are the real estate operating company requirements?', 'value': 'What are the real estate operating company requirements?'},
    {'text': 'Can the business obtain a real estate loan?', 'value': 'Can the business obtain a real estate loan?'},
    {'text': 'Can I rent the property to my parents?', 'value': 'Can I rent the property to my parents?'},
    {'text': 'Can I work on the property?', 'value': 'Can I work on the property?'},
    {'text': 'Can I hold each property in an LLC?', 'value': 'Can I hold each property in an LLC?'},
    {'text': 'Can I fund a vacation rental business?', 'value': 'Can I fund a vacation rental business?'},
    {'text': 'Can I take a salary right away?', 'value': 'Can I take a salary right away?'},
    {'text': 'Can I receive my realtor commission when the business sells a property?', 'value': 'Can I receive my realtor commission when the business sells a property?'}
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListRealEstateoc = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
