import { Outlet, NavLink, Link, useNavigate  } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import styles from "./Layout.module.css";
import onedigital from "../../assets/logo_A.png";
import { useLocation } from 'react-router-dom';
import { ChatEmailRequest, chatApi, emaillogsApi } from "../../api";
import Cookies from "js-cookie";

Cookies.set("time", String(Date.now()))



const Layout = () => {
  const initialTime = 25 * 60; // 5 minutes in seconds
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(timerInterval);
        const time = Cookies.get("time")
        Cookies.set("time", String(Date.now()))
        const request: ChatEmailRequest = {
            time: time
        };
        // emaillogsApi(request);

 

        // Reset the timer to the initial time
        setTimeLeft(initialTime);
      }
    }, 1000); // Update every second

    return () => {
      clearInterval(timerInterval);
    };
  }, [timeLeft, initialTime]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="https://www.mysolo401k.net/" className={styles.headerTitleContainer}>
                        <img src={onedigital} alt="My Solo 401k Financial logo" className={styles.oneDigitalLogo} />
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/solo401k" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    SOLO 401K
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/robs401k" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    ROBS 401K
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/irallc" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    IRA LLC
                                </NavLink>
                            </li> 

                            
                            {/* <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Ask a question
                                </NavLink>
                            </li> */}
                        </ul>
                    </nav>
                    
                    <h4 className={styles.headerRightText}></h4>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
