import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    
    {
        text: "Open Account",
        value: "Open Account"
    },
    {
        text: "Investments",
        value: "Investments"
    }
];
interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleListOuter = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
