import { Example } from "./Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "Can I make 100% After-Tax Solo 401k Contributions (Mega Backdoor Roth)?",
        value: "Can I make 100% After-Tax Solo 401k Contributions (Mega Backdoor Roth)?"
    },
    {
        text: "Is a Form 1099-R Required for Voluntary After-Tax Solo 401k Contributions?",
        value: "Is a Form 1099-R Required for Voluntary After-Tax Solo 401k Contributions?"
    },
    {
        text: "Can I contribute to both Roth IRA and Mega Backdoor Roth Solo 401K?",
        value: "Can I contribute to both Roth IRA and Mega Backdoor Roth Solo 401K?"
    },
    {
        text: "Do I make Roth Solo 401k First Before After tax Solo 401k contributions?",
        value: "Do I make Roth Solo 401k First Before After tax Solo 401k contributions?"
    },
    {
        text: "Does IRA Pro Rata Rule Apply to Mega Backdoor Roth Solo 401k?",
        value: "Does IRA Pro Rata Rule Apply to Mega Backdoor Roth Solo 401k?"
    },
    {
        text: "Do I need to report after-tax contributions on my W-2?",
        value: "Do I need to report after-tax contributions on my W-2?"
    },
    {
        text: "Can I upgrade my existing Solo 401k at Fidelity to a Mega Backdoor Roth Solo 401k?",
        value: "Can I upgrade my existing Solo 401k at Fidelity to a Mega Backdoor Roth Solo 401k?"
    },
    {
        text: "When is the 1099 R issued for Mega Backdoor Roth Solo 401k?",
        value: "When is the 1099 R issued for Mega Backdoor Roth Solo 401k?"
    },
    {
        text: "Can I make Mega Backdoor Roth Solo 401k contributions with a personal check?",
        value: "Can I make Mega Backdoor Roth Solo 401k contributions with a personal check?"
    },
    {
        text: "Can I make Mega Backdoor Roth Solo 401k contributions if I max out my 401k at my day job?",
        value: "Can I make Mega Backdoor Roth Solo 401k contributions if I max out my 401k at my day job?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

// Function to shuffle an array randomly

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleListMegaBackdoorRoth = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
