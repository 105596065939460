import { Example } from "../ExampleOpenAccount/Example";

import styles from "./Example.module.css";
import { Send28Filled } from "@fluentui/react-icons";
export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {'text': 'What are the advantages of a ROBS 401k?', 'value': 'What are the advantages of a ROBS 401k?'},
    {'text': 'What is the initial cost and ongoing fees?', 'value': 'What is the initial cost and ongoing fees?'},
    {'text': 'Can the company reimburse me for paying your fee?', 'value': 'Can the company reimburse me for paying your fee?'},
    {'text': 'How long does it take to fund my business?', 'value': 'How long does it take to fund my business?'},
    {'text': 'What are the eligibility requirements?', 'value': 'What are the eligibility requirements?'},
    {'text': 'Where are my accounts?', 'value': 'Where are my accounts?'},
    {'text': 'Can I rollover funds from 401k at my current job?', 'value': 'Can I rollover funds from 401k at my current job?'},
    {'text': 'Can my spouse work in the business?', 'value': 'Can my spouse work in the business?'},
    {'text': 'Can other investors invest non-retirement funds in the business?', 'value': 'Can other investors invest non-retirement funds in the business?'},
    {'text': 'Can I use a ROBS to fund my existing business?', 'value': 'Can I use a ROBS to fund my existing business?'},
    {'text': 'Can I use a RoBS 401k to fund a downpayment on an SBA Loan?', 'value': 'Can I use a RoBS 401k to fund a downpayment on an SBA Loan?'}
];

interface Props {
    onExampleClicked: (value: string) => void;
}
function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array; // Return the shuffled array
  }
const shuffledExamples = shuffleArray([...EXAMPLES]);

  // Get the first 4 items from the shuffled array
const randomItems = shuffledExamples.slice(0, 4);
export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {randomItems.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
                
            ))}
        </ul>
        
    );
};
